.ant-modal-mask{
    z-index: 2300 !important;
}

.ant-modal-wrap{
    z-index: 2300 !important;
}

.ant-notification{
    z-index: 2300 !important;
}

.MuiTableCell-root{
    padding: 0;
}