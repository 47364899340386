.floatingDrop{
    max-width: 30%;
}

.ant-select-dropdown{
    z-index: 2000 !important;
    font-size: 10px;
}

.ant-notification{
    z-index: 2300 !important;
}
