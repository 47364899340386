.ant-spin-container{
    z-index: inherit;
}

.controls {
    display: flex;
    border: 1px solid #ccc;
    border-top: 0;
    padding: 10px;
  }
  
  .controls-right {
    margin-left: auto;
  }
  
  .state {
    margin: 10px 0;
    font-family: monospace;
  }
  
  .state-title {
    color: #999;
    text-transform: uppercase;
  }